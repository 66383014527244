// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navigation-meter-expiration-warning {
  color: red;
  background-color: #ffd700;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/meter-expiration-navigate/meter-expiration-navigate.component.scss","webpack://./src/variables.scss"],"names":[],"mappings":"AAEA;EACI,UAAA;EACA,yBCJa;EDKb,kBAAA;EACA,0BAAA;EACA,eAAA;AADJ","sourcesContent":["@import \"variables\";\n\n.navigation-meter-expiration-warning {\n    color:red;\n    background-color: $main-backgroud;\n    text-align: center;\n    text-decoration: underline;\n    cursor: pointer;\n}","$main-backgroud: #ffd700;\n// 本番（PRD）：背景色を薄青（#90CAFC）にする\n// STG：背景色を薄緑（#66cdaa）にする\n// DEV：背景色を黄色（#ffd700）にする\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
