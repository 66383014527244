// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// devの設定情報
export const environment = {
  production: false,
  amplify: {
    Auth: {
      // Auth以下はこの変数名じゃないとユーザプールが見つけられなかった
      aws_cognito_region: 'ap-northeast-1',
      aws_user_pools_id: 'ap-northeast-1_SCz6J1r2y',
      aws_user_pools_web_client_id: '1ntfd7kf4fa9bjpushlkc74pmj',
    }
  },
  apiUrl: 'https://api.dev.rakusugikenshin.com',
  apiKey: 'JtbiCIBxrV7wwnmFB7jyGaSL4QPbnjtv71kKfDSs',
  apiEnv: 'dev',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
